<template>
  <b-container fluid>
    <b-row>
      <div class="iq-top-navbar">
        <div class="iq-navbar-custom">
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="iq-navbar-logo d-flex justify-content-between">
                <img :src="orgData.org_profile_img" class="img-fluid navBarLogo mt-3" alt="logo">
            </div>
          </nav>
        </div>
      </div>

      <b-col md="8" lg="8" class="" v-if="operatingsystem == 'web'">
        <iq-card class="p-4 banner-text-left-card">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <img :src="orgData.org_cover_img" style="width:100%" />
            <!-- Event Description -->
            <div class="col-12 mt-4">
              <span id="contactUsV1EventDescId" v-html="orgData.org_desc"></span>
            </div><!-- Event Description -->
          </template>
        </iq-card>
      </b-col>

      <b-col md="12" lg="8" class="" v-if="operatingsystem !== 'web'">
        <iq-card class="ban_card">
          <template v-slot:headerTitle>
          </template>
           <img v-if="!propOpenedInModal" :src="orgData.org_cover_img" style="width:100%" />
        </iq-card>
      </b-col>

      <b-col lg="4" style='background-color:#fff'>
        <div class="p-3">
          <h3 class="mb-2">
            <span class="primary-color">{{orgData.org_name}}</span>
          </h3>
          <h3 class="mb-0">
            Email OTP Verification
          </h3>
          <ValidationObserver ref="userActivationForm">
            <form class="mt-4">
              <div class="form-group">
                <ValidationProvider name="Activation code" rules="required" v-slot="{ errors }">
                  <input v-model="userActivationCode" type="text" class="form-control mb-0" id="exampleInputPassword1" placeholder="Enter OTP Here">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="d-inline-block w-100">
                <button class="btn btn-primary" type="button" v-if="resendEmailBtn" @click="userActivationEmail()">
                  Resend Email
                </button>
                <button type="button" @click="userActivate" class="btn btn-primary float-right">
                  Validate
                </button>
              </div>
              <span v-if="!resendEmailBtn" @click="userActivationEmail()">
                otp sent to {{userEmail}} <br/>You can request for otp after 10 secs
              </span>
              <div class="sign-info">
                <span class="dark-color d-inline-block line-height-2">Go back to <router-link :to="{ path: 'login'}">Sign In</router-link></span>
              </div>
            </form>
          </ValidationObserver>
          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
<script>
import { User } from "../FackApi/api/user.js"
import { Organisations } from "../FackApi/api/organisation.js"
import ApiResponse from "../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"

export default {
  name: "Activate",
  components: {
    ValidationProvider
  },
  data: () => ({
    showToast: false,
    toastMsg: null,
    toastVariant: "default",
    toastTitle: "Registration Response",
    userEmail: "",
    userId: null,
    userActivationCode: "",
    operatingsystem: window.__DEVICE_OS__,
    resendEmailBtn: false,
    orgData: {
      org_id: "GIDE_ORG_1",
      user_id: 1,
      acc_id: 1,
      org_name: "GIDE.AI",
      type: "ORG",
      org_desc: `<h1><strong style="color: rgb(226, 38, 44);">GIDE.AI</strong><strong>: </strong>Your Gateway to Scholarships and Career Success</h1><p><strong style="color: rgb(230, 0, 0);">Brought to you by Asia's largest Student and Parent community - High School Moms (HSM)</strong></p><p>Access futuristic career assessment, profile building, test preparation, great scholarships, and career guidance all in one integrated experience.</p><p><br></p>`,
      org_website_email: "admin@gide.ai",
      org_admin_email: "admin@gide.ai",
      org_admission_email: "admin@gide.ai",
      org_admission_requirement: null,
      org_top_alums: null,
      org_major_industries_around: null,
      org_clubs: null,
      org_college_basis_app: null,
      org_city: null,
      org_country: null,
      org_founding_year: null,
      org_deadline: null,
      org_degrees: null,
      org_intake_count: null,
      org_intake_type: null,
      org_currency: null,
      org_url: null,
      org_website: null,
      org_domain: "web.gide.ai",
      org_uid: "",
      org_domain_is_validated: 99,
      org_profile_img: "https://web.gide.ai/img/GideWhiteNRed.2533ee45.jpeg",
      org_cover_img: "https://gide-assets.s3.amazonaws.com/GIDECover.White.png"
    },
    propOpenedInModal: false
  }),
  async mounted () {
    await this.getOrgDetails()
    this.showResendEmail()

    if (this.$route.query.code) {
      let code = JSON.parse(atob(this.$route.query.code))
      this.userEmail = code.user_email
      this.userId = code.user_id = 0 || code.user_id == "0" ? null : code.user_id
      this.userActivationCode = code.otp
    }
    else if (this.userData) {
      this.userEmail = this.userData.user_email
      this.show_user_email = true
    }
    else {
      this.$router.push("/login")
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    saasOrgData () {
      return this.$store.getters["SaasOrgData/getSaasOrgData"]
    }
  },
  methods: {
    /**
     * showResendEmail
     */
    showResendEmail () {
      setTimeout(() => {
        this.resendEmailBtn = true
      }, 10000)
    },
    /**
     * userActivationEmail
     */
    async userActivationEmail () {
      await User.userActivationEmail(this, this.userEmail)
      this.toastMsg = "Email Resent"
      this.showToast = true
      this.toastVariant = "success"
      this.resendEmailBtn = false
      this.showResendEmail()
    },
    /**
     * userActivate
     */
    async userActivate () {
      let valid = await this.$refs.userActivationForm.validate()
      if (!valid) {
        return
      }

      let userObj = {
        "user_email": this.userEmail,
        "user_id": this.userData && this.userData.user_id ? this.userData.user_id : this.userId,
        "activation_code": this.userActivationCode
      }
      let userActivateResp = await User.userActivate(this, userObj)
      await ApiResponse.responseMessageDisplay(this, userActivateResp)
      if (userActivateResp.resp_status) {
        // If user not found than set basic profile details and redirect to registartion page
        // let userProfileData = {
        //   user_email: this.userEmail,
        //   is_profile_complete: false
        // }
        this.$store.dispatch("User/setUserProfileAction", userActivateResp.resp_data.data)

        setTimeout(() => {
          this.$router.push("/quick-profile")
        }, 1000)
      }
    },
    /**
     * getOrgDetails
     */
    async getOrgDetails () {
      try {
        // Do not get the org_details if running on localhost, for localdns we have to set the org_domain in DB
        if (process.env.VUE_APP_ENV == "local") {
          this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
          return
        }

        const orgDomain = window.location.hostname
        if (this.saasOrgData) {
          // Found in Cache
          this.orgData = this.saasOrgData
        }
        else {
          const orgDetails = await Organisations.organisationView(this, this.orgId, orgDomain)
          if (!orgDetails.resp_status) {
            // set the details of GIDE_ORG_1 as default
            this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
            return
          }

          this.orgData = orgDetails.resp_data.data
          // set the org data in local storage for saving in user table
          this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)

          // set the source for the user
          let source = this.orgData.org_uid
          if (this.sourceData) {
            source = this.orgData.org_uid + "_" + this.sourceData
          }

          this.$store.dispatch("SourceTracker/setCodeAction", source)
        }
      }
      catch (err) {
        console.error("Exception in getOrgDetails and err: ", err)
      }
    }
  }
}
</script>
